



import { AuthClient } from '@/services/auth';
import { Component, Vue } from 'vue-property-decorator';

@Component<LogoutPage>({})
export default class LogoutPage extends Vue {
  private async mounted() {
    AuthClient.getInstance().signOut();
    this.$router.push({
      name: 'home'
    });
  }
}
